<template>
  <teleport to="body">
    <div
      :id="id"
      ref="element"
      class="modal"
      :class="[modalClasses, { fade: !noFadeBoolean }]"
      role="dialog"
      :aria-labelledby="`${computedId}label_`"
      :aria-describedby="`${computedId}body_`"
      tabindex="-1"
      aria-hidden="true"
      :data-bs-backdrop="noCloseOnBackdrop ? 'static' : null"
      :data-bs-keyboard="noCloseOnBackdrop ? 'false' : null"
      @click="onClickBackdrop"
    >
      <div class="modal-dialog" :class="modalDialogClasses">
        <div v-if="!lazyBoolean || lazyLoadCompleted" class="modal-content" :class="contentClasses">
          <div v-if="!hideHeaderBoolean" :id="`${computedId}modal-header_`" class="modal-header" :class="headerClasses">
            <slot name="header" />
          </div>
          <div :id="`${computedId}modal-body_`" class="modal-body" :class="bodyClasses">
            <slot />
          </div>
          <div v-if="!hideFooterBoolean" :id="`${computedId}modal-footer_`" class="modal-footer" :class="footerClasses">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, nextTick, getCurrentInstance, toRef } from 'vue'
import mitt from 'mitt'
import { useId } from '~/composables/base'
import { ModalEvent } from '~/types/event.type'

const props = defineProps({
  id: { type: String, required: true },
  lazy: { type: Boolean, default: false },
  show: { type: Boolean, default: false },
  centered: { type: Boolean, default: false },
  fullscreen: { type: Boolean, default: false },
  screenSize: { type: String, default: null },
  size: { type: String, default: null },
  hideFooter: { type: Boolean, default: false },
  hideHeader: { type: Boolean, default: false },
  noCloseOnBackdrop: { type: Boolean, default: false },
  noFade: { type: Boolean, default: false },
  noFocus: { type: Boolean, default: false },
  scrollable: { type: Boolean, default: false },
  footerClass: { type: [String, Object, Array], default: null },
  headerClass: { type: [String, Object, Array], default: null },
  modalClass: { type: [String, Object, Array], default: null },
  bodyClass: { type: [String, Object, Array], default: null },
  contentClass: { type: [String, Object, Array], default: null },
  dialogClass: { type: [String, Object, Array], default: null }
})

const eventBus = mitt<ModalEvent>()
const emit = defineEmits(['show', 'hide'])
const { closeModal } = useModal()

const computedId = useId(toRef(props, 'id'), 'modal')
const lazyBoolean = toRef(props, 'lazy')
const centeredBoolean = toRef(props, 'centered')
const hideFooterBoolean = toRef(props, 'hideFooter')
const hideHeaderBoolean = toRef(props, 'hideHeader')
const noFadeBoolean = toRef(props, 'noFade')
const noFocusBoolean = toRef(props, 'noFocus')
const scrollableBoolean = toRef(props, 'scrollable')

const isActive = ref(false)
const element = ref<HTMLElement | null>(null)
const lazyLoadCompleted = ref(false)

const modalClasses = computed(() => [props.modalClass, { show: isActive.value }])

const modalDialogClasses = computed(() => [
  props.dialogClass,
  {
    'modal-fullscreen': props.fullscreen,
    [`modal-fullscreen-${props.screenSize}-down`]: props.screenSize,
    [`modal-${props.size}`]: props.size,
    'modal-dialog-centered': centeredBoolean.value,
    'modal-dialog-scrollable': scrollableBoolean.value
  }
])

// Classes for modal sections
const bodyClasses = computed(() => [props.bodyClass])
const contentClasses = computed(() => [props.contentClass])
const headerClasses = computed(() => [props.headerClass])
const footerClasses = computed(() => [props.footerClass])

const hide = () => {
  emit('hide')
  if (props.id) {
    closeModal(props.id)
  }
}

onMounted(() => {
  const instance = getCurrentInstance()
  if (instance) {
    const { scopeId } = instance.vnode
    nextTick(() => {
      if (element.value) {
        if (scopeId) {
          element.value.setAttribute(scopeId, '')
        }
      }
      const backdropEl = document.querySelector('.modal-backdrop')
      backdropEl?.remove?.()
    })

    eventBus.on('bv::show::modal', ({ modalId }) => {
      if (modalId === props.id) {
        lazyLoadCompleted.value = true
        emit('show')
        nextTick(() => {
          if (!noFocusBoolean.value && element.value) {
            // element.value.focus()
          }
        })
      }
    })

    eventBus.on('bv::hide::modal', (payload: { modalId: string }) => {
      if (payload.modalId === props.id) {
        emit('hide')
      }
    })
  }
})

const onClickBackdrop = (e: any) => {
  if (e.target.id === props.id && !props.noCloseOnBackdrop) {
    hide()
  }
}

onUnmounted(() => {
  eventBus.off('bv::show::modal')
  eventBus.off('bv::hide::modal')
})
</script>
